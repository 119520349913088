import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import SideHeader from "../components/SideHeader";
import PageContainer from "../container/CustomPage";
import PortfolioDetailsThumb from "../templates/portfolio/details/thumbnail";
import Lucas from "../Media/New_Media/Lucas_port.jpg";
import Lucas_Position from "../Media/New_Media/Lucas_position.png";

import Footer from "./Footer";
import Header from "./Header";




const TemplatePortfolioDetails = () => {
    return (
      <div>
      <Header classes={'position-static'}/>
      <SideHeader mobile={true}/>
            <Container>
                <Row>
                    <Col lg={7}>
                      <img src={Lucas} alt="PicLucas"/>
                    </Col>

                    <Col lg={5}>
                    <div className="portfolio-details-content">

                        <a href="/" className="category"> </a>

                        <h1 className="title"> Lucas Gourna-Douath </h1>


                        <ul className="project-info">
                            <li><span className="color1">Année de naissance :</span> 2003</li>
                            <li><span className="color1">Club :</span> AS Saint-Etienne </li>
                            <li><span className="color1">National :</span> Equipe de France U17 </li>

                        </ul>

                        <div className="project-share">
                            <span className="color1">Position :</span>
                            <img src={Lucas_Position} alt="Position_Lucas" className="size_field"/>

                        </div>

                        <div className="project-share">
                            <span className="color1">Réseaux :</span>
                            <a href="https://www.instagram.com/l.gourna27/?hl=fr"><i className="fa fa-instagram"/></a>

                        </div>

                    </div>
                    </Col>
                </Row>
            </Container>
            <Footer position={'static'}/>
</div>
    );
};

export default TemplatePortfolioDetails;
