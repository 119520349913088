import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Content from "../components/content";
import aboutData from "../data/AboutMe";
import Joris from "../Media/New_Media/Joris_bas.jpg";
import Sylvain from "../Media/New_Media/Sylvain.jpg";
import Momo from "../Media/New_Media/Momo.jpg";
import Desire from "../Media/New_Media/Desire.jpg";

const AboutMe = ({ type }) => {
  return (
    <div className={type !== "page" ? "section-padding section" : null}>
      <Container>
        <Row className={"align-items-center"}>
          <Col id="Jo" lg={6}>
            <img src={Joris} alt="image joris" />
          </Col>

          <Col lg={6} className="justify">
            <Content classes={"about-content"}>
              {type !== "page" ? (
                <h3 className="block-title">Joris GAKPE</h3>
              ) : null}
              <h5>Fondateur - agent FFF & FFBB</h5>
              <br />
              <p>{aboutData.bio}</p>
              <ul className="personal-info">
                <li>
                  <span>Sport : </span> Football & Basketball{" "}
                </li>
                <li>
                  <span>Licences : </span> FFF & FFBB
                </li>
              </ul>
            </Content>
          </Col>
        </Row>
        {/* <Row className={"align-items-center"} className="justify">
          <Col lg={6}>
            <Content classes={"about-content"} className="fond-team">
              <br />
              {type !== "page" ? (
                <h3 className="block-title">Sylvain BALAGNE</h3>
              ) : null}
              <h5>Conseiller sportif</h5>
              <br />
              <span>
                {" "}
                De formation éducateur spécialisé, Sylvain possède une réelle
                expertise dans l'accompagnement et les sujets importants liés à
                la jeunesse. Passionné de football, il a été très présent pour
                suivre la carrière de jeunes joueurs. <br /> <br />
                "J'ai vu énormément de jeunes talents échouer car ils n’ont pas
                été bien accompagnés dans leur carrière. Je veux leur apporter
                toutes les clés nécessaires pour réussir. Mon rôle de conseiller
                sportif passe par la confiance avec les joueurs et leur famille
                et l'écoute de leurs envies" <br />{" "}
              </span>
              <ul className="personal-info">
                <li>
                  <span>
                    <br />
                    Sport :{" "}
                  </span>{" "}
                  Football
                </li>
              </ul>
            </Content>
          </Col>

          <Col lg={6}>
            <img src={Sylvain} alt="image Sylvain" />
          </Col>
        </Row> */}
        {/* <Row className={"align-items-center"} className="justify">
          <Col lg={5}>
            <img src={Momo} alt="image Momo" />
          </Col>

          <Col lg={6}>
            <Content classes={"about-content"}>
              <br />
              {type !== "page" ? (
                <h3 className="block-title">Mohamed ABOUDOU</h3>
              ) : null}
              <h5>Conseiller sportif & conseiller en Management </h5>
              <br />
              <p>
                Ayant côtoyé de près les clubs de formation, sa mission est de
                travailler sur le développement personnel du joueur et de le
                conseiller sur ses compétences techniques. Il a également un
                rôle de scouting sur toute l’Île-de-France <br /> <br />
                "Sur un même terrain, plusieurs jeunes joueurs pros posséderont
                les mêmes facultés techniques. C'est la force mentale qui les
                démarquera. Le développement personnel est devenu un outil
                essentiel dans le sport moderne face à la pression de la
                surexposition. Je serai là pour les accompagner."
              </p>
              <ul className="personal-info">
                <li>
                  <span>Sport : </span> Football{" "}
                </li>
              </ul>
            </Content>
          </Col>
        </Row> */}
        {/* <Row className={"align-items-center"} className="justify">
          <Col lg={6} className="">
            <Content classes={"about-content"} className="fond-team">
              <br />
              {type !== "page" ? (
                <h3 className="block-title">Désiré KABAMBA</h3>
              ) : null}
              <h5>Conseiller sportif</h5>
              <br />
              <span>
                {" "}
                Ancien sportif de haut niveau et ancien président d'un club de
                football, il excelle également dans le métier de consultant. De
                par son expérience sportive, il connaît l'enjeu et les
                préoccupations liées aux aspects financiers d'un athlète. Il
                assure à la fois le rôle de conseiller sportif pour la
                négociation de contrats, mais également de consultant financier.{" "}
                <br /> <br />
                "Étant un ancien joueur formé au football professionnel, je
                connais l'enjeu et la pression que porte un jeune talent. Avec
                ma maturité à ce sujet, j'aimerais accompagner le joueur à
                prendre les meilleurs décisions pour sa carrière" <br />{" "}
              </span>
              <ul className="personal-info">
                <li>
                  <span>
                    <br />
                    Sport :{" "}
                  </span>{" "}
                  Football
                </li>
              </ul>
            </Content>
          </Col>

          <Col lg={6}>
            <img src={Desire} alt="image Désiré" />
          </Col>
        </Row> */}
      </Container>
    </div>
  );
};

export default AboutMe;
