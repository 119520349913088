import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import PageContainer from "../container/CustomPage";
import SideHeader from "../components/SideHeader";
import PortfolioDetailsThumb from "../templates/portfolio/details/thumbnail";
import Lucas from "../Media/Lucas.jpeg";
import Footer from "./Footer";
import Header from "./Header";
import Contact from "./Contact";




const TemplatePortfolioDetails = () => {
    return (
      <div>
      <Header classes={'position-static'}/>
      <SideHeader mobile={true}/>

            <Container>

            <Contact/>

            </Container>
            <Footer position={'static'}/>
</div>
    );
};

export default TemplatePortfolioDetails;
