import React, {Fragment, useState} from 'react';
import Header from "../components/header/HeaderOne";
import SideHeader from "../components/SideHeader";
import PortfolioGridThreeColumn from "../templates/portfolio/grid/three-column";
import Footer from "./Footer";
import portfolioData from '../data/portfolio'

import Joris from '../Media/Joris.jpeg';
import Team_page0 from './team_page0';

import { Container, Row, Col } from 'reactstrap';


const PortfolioGridThreeColumnPage = () => {
    const [count, setCount] = useState(9);
    const [loading, setLoading] = useState(false);
    const portfolios = portfolioData.slice(0, count);

    const loadMore = () => {
        setLoading(true);
        setTimeout(() => {
            setCount(count + 3);
            setLoading(false);
        }, 200);
    };

    const allItems = count >= portfolioData.length;

    return (
        <Fragment>
            <Header classes={'position-static'}/>
            <SideHeader mobile={true}/>
            <Team_page0/>
            <Footer position={'static'}/>
        </Fragment>
    );
};

export default PortfolioGridThreeColumnPage;
