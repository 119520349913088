import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import Logo from  "../Media/New_Media/logo_STM-NOIR-RVB.png";


const FooterThree = ({position}) => {
    return (
        <div className={`footer-section section position-${position} pt-30 pb-30`}>
            <Container fluid className="border">
                <Row className={'align-items-center'}>
                    <Col className={'footer-copyright text-center'} className="left_footer" md="5">
                    <img src={Logo} alt="logoFooter" className="logo_footer"/>
                        <h5><em> Sports Talents Management</em> </h5>
                        <h5><span>Agence de Football et de Basketball</span></h5>
                        <br/>
                        {/* <h3 className="color1">Contactez nous : </h3> */}
                        {/* <span> Contact@stmanagement.fr</span> */}
                    </Col>
                    <Col md="2">
                    <h3 className="color1">Navigation</h3>
                    <br/>
                    <a href="/" className="center_footer"> Home</a> <br/>
                    {/* <a href="/Equipe"> Talents</a> <br/> */}
                    <a href="/team_page">L'agence</a> <br/>
                    <a href="/Contact">Contact</a>

                    </Col>
                    <Col className="3">
                    <p>&copy; {new Date().getFullYear()} Sports Talents Management </p>
                    <p className="allright">All Rights Reserved</p>
                    <br/><br/><br/> <p className="right_footer"> Designed and coded by <a href="https://jgakpe.com" className="linkgak">GKP</a></p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default FooterThree;
