import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import SectionTitle from "../components/SectionTitle";
import Team from './team-detail';
import teams from '../data/team';
import {Link} from 'react-router-dom';

const PersonalPortfolioTeam = () => {
    return (
        <></>
        // <div className="section pt-120 pt-lg-80 pt-md-80 pt-sm-80 pt-xs-50 pb-120 pb-lg-80 pb-md-80 pb-sm-80 pb-xs-50">
        //     <div className="section-wrap section">
        //         <Container>
        //             <Row>
        //                 <Col>
        //                     <SectionTitle
        //                         title= "L'équipe STM"
        //                         content="Notre équipe regroupe plusieurs compétences et personnalités pour répondre à tous les besoins de nos joueurs au cours de leur carrière."
        //                     />
        //                 </Col>
        //             </Row>

        //             <Row className={'mbn-30'}>
        //                 {teams.slice(0, 3).map(member => (
        //                     <Col key={member.id} md={6} lg={4} className={'mb-30'}>
        //                        <Link to="team_page#Jo">
        //                         <Team
        //                             name={member.name}
        //                             designation={member.designation}
        //                             profilePhoto={member.profilePhoto}
        //                             socials={member.socials}
        //                         />
        //                       </Link>
        //                     </Col>
        //                 ))}
        //             </Row>
        //         </Container>
        //     </div>
        // </div>
    );
};

export default PersonalPortfolioTeam;
