import React, {Fragment, useState} from 'react';

// File imported
import Header from "./Header";
import SideHeader from "../components/SideHeader";
import Banner from "./banner";
import AboutMe from "./About-me";
import PersonalPortfolio from "../container/portfolio/PersonalPortfolio";
import Services from "./services";
import Team from "./team";
import Contact from "./Contact";
import Footer from "./Footer";

const Home = () => {
    return (
        <div className={'main-wrapper p-0'}>
        <Fragment>
            <Header classes={'position-static'}/>
            <SideHeader mobile={true}/>
            <Banner/>
            <AboutMe/>
            <Services/>
            <Team/>
            <Contact/>
            <Footer position={'static'}/>
        </Fragment>
        </div>
    );
};

export default Home;
