import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import SideHeader from "../components/SideHeader";
import PageContainer from "../container/CustomPage";
import PortfolioDetailsThumb from "../templates/portfolio/details/thumbnail";
import Gaoussou from "../Media/New_Media/Gaou_port.jpg";
import Footer from "./Footer";
import Header from "./Header";

import Lucas_Position from "../Media/New_Media/Lucas_position.png";



const TemplatePortfolioDetails = () => {
    return (
      <div>
      <Header classes={'position-static'}/>
      <SideHeader mobile={true}/>
            <Container>
                <Row>
                    <Col lg={7}>
                      <img src={Gaoussou} alt="PicGaoussou"/>
                    </Col>

                    <Col lg={5}>
                    <div className="portfolio-details-content">

                        <h1 className="title"> Gaoussou Traore </h1>


                        <ul className="project-info">
                            <li><span className="color1">Année de naissance :</span> 1999</li>
                            <li><span className="color1">Club :</span> Amien SC </li>
                        </ul>

                        <div className="project-share">
                            <span className="color1">Position :</span>
                            <img src={Lucas_Position} alt="Position_Lucas" className="size_field"/>

                        </div>

                        <div className="project-share">
                            <span className="color1"> Réseaux :</span>
                            <a href="https://www.instagram.com/traore_14/?hl=fr"><i className="fa fa-instagram"/></a>

                        </div>

                    </div>
                    </Col>
                </Row>
            </Container>
            <Footer position={'static'}/>
</div>
    );
};

export default TemplatePortfolioDetails;
