import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import PageContainer from "../../container/CustomPage";
import PortfolioDetailsThumb from "../../templates/portfolio/details/thumbnail";
import PortfolioDetailsContent from "../../templates/portfolio/details/content";
import portfolioData from './PortfolioLongHeight'

const TemplatePortfolioDetails = () => {
    return (
        <PageContainer classes={'bg-grey'}>
            <Container>
                <Row>
                    <Col lg={7}>
                        <PortfolioDetailsThumb images={portfolioData.previewImages}/>
                    </Col>

                    <Col lg={5}>
                    {portfolioData.map(portfolio => (
                        <div key={portfolio.id} className="col pl-5 pr-5">
                            <PortfolioDetailsContent
                                title={portfolio.title}
                                category={portfolio.category}
                                description={portfolio.description}
                                classes={'portfolio-screen-height'}
                            />
                        </div>
                    ))}

                    </Col>
                </Row>
            </Container>
        </PageContainer>
    );
};

export default TemplatePortfolioDetails;
