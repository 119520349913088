import React from 'react';
import HeroBannerContainer from "../container/HeroBanner";
import bannerBg from '../Media/Basket_Foot_JGKP.jpg'
import personalInfo from '../data/PersonalInfo';
import TypeWriterEffect from 'react-typewriter-effect';


const BannerFreelancerPortfolio = () => {
    return (
        <HeroBannerContainer bg={bannerBg} overlay={true}>
            <div className={'text-center'}>
                <div className="slider-banner-info text-center">
                    <h2 className="slider-banner-title"> Sports Talents Management</h2>
                    <h5 className="slider-banner-text">

                    <TypeWriterEffect
                       textStyle={{
                         fontFamily: 'Red Hat Display',
                         color: '#3F3D56',
                         fontWeight: 500,
                         fontSize: '1.5em',
                       }}
                       startDelay={1000}
                       cursorColor="#3F3D56"
                       multiText={[
                         "Bienvenue chez STM",
                         "L'agence de talents",
                         "Nous accompagnons nos clients dans la réalisation de leur objectif",
                         "Devenir des athlètes de haut niveau",
                         "Let's goal",
                         "Contact@stmanagement.fr",
                       ]}
                       loop={true}
                       nextTextDelay={1}
                       typeSpeed={30}
                     />
                    </h5>
                </div>
            </div>
        </HeroBannerContainer>
    );
};

export default BannerFreelancerPortfolio;
