import React from 'react';
import {Container} from 'react-bootstrap'
import Test from "../../Media/Basket_Foot_JGKP.jpg"

const HeroBannerContainer = ({children, overlay, bg}) => {
    return (
        <div className={`section main-slider-1 ${overlay ? 'banner-overlay' : null}`} style={{backgroundImage: `url(${Test})`}}>
            <Container>
                {children}
            </Container>
        </div>
    );
};

export default HeroBannerContainer;
