import React from 'react';
import Logo from "../components/logo";
import NavBar from "../components/navbar";
import HeaderSocial from "../components/HeaderSocial";
import HeaderContainer from "../container/header";

const Header = ({classes}) => {
    return (
        <HeaderContainer classes={classes ? classes : null}>
            <Logo/>
            <NavBar/>
            <HeaderSocial/>
        </HeaderContainer>
    );
};

export default Header;
