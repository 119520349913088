import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import Thumbnail from "../components/thumbnail";
import Content from "../components/content";
import Skill from "../components/skill";
import aboutData from '../data/AboutMe'
import Joris from '../Media/Joris_Home.jpg';

const AboutMe = ({type}) => {
    return (
        <div className={type !== 'page' ? 'section-padding section' : null}>
            <Container>
                <Row className="align-items-center justify-text">
                    <Col lg={6}>
                      <img src={Joris} alt="image joris" className="jorispic"/>
                    </Col>

                    <Col lg={6}>
                        <Content classes={'about-content'}>
                        <br/>
                            {type !== 'page' ? (<h3 className="block-title">À PROPOS</h3>) : null}
                            <p>{aboutData.bio}</p>
                            <br/>
                            <p>{aboutData.bio2}</p>

                            <ul className="personal-info">
                                <li><span>Sport:</span> Football & Basketball </li>
                                <li><span>Licences:</span> FFF & FFBB</li>
                            </ul>
                            <hr/>
                              <h3 className="block-title" >Notre mission</h3>
                            <p>{aboutData.bio3}</p>

                        </Content>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AboutMe;
