import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import PageContainer from "../container/CustomPage";
import SideHeader from "../components/SideHeader";
import PortfolioDetailsThumb from "../templates/portfolio/details/thumbnail";
import Amadou from "../Media/New_Media/Amadou_port.jpg";
import Footer from "./Footer";
import Header from "./Header";

import Amadou_Position from "../Media/New_Media/Position_Amadou.png";


const TemplatePortfolioDetails = () => {
    return (
      <div>
      <Header classes={'position-static'}/>
      <SideHeader mobile={true}/>
            <Container>
                <Row>
                    <Col lg={7}>
                      <img src={Amadou} alt="PicAmadou"/>
                    </Col>

                    <Col lg={5}>
                    <div className="portfolio-details-content">

                        <h1 className="title"> Amadou Mbengue </h1>


                        <ul className="project-info">
                            <li><span className="color1">Année de naissance :</span> 2003</li>
                            <li><span className="color1">Club :</span> FC Metz</li>
                        </ul>

                        <div className="project-share">
                            <span className="color1">Position :</span>
                            <img src={Amadou_Position} alt="Amadou Position" className="size_field"/>
                        </div>

                        <div className="project-share">
                            <span className="color1">Share :</span>
                            <a href="https://www.instagram.com/amadou_am6/?hl=fr"><i className="fa fa-instagram"/></a>

                        </div>

                    </div>
                    </Col>
                </Row>
            </Container>
            <Footer position={'static'}/>
</div>
    );
};

export default TemplatePortfolioDetails;
